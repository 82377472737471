'use client';

import { Status, Size } from '@/utils/enums';
import clsx from 'clsx';

import * as S from './styles';

type Contrast = 'high' | 'low';

type Variations = 'badge' | 'iconBadge' | 'notification' | 'newBadge';

type BadgeProps = {
  size: Size | 'xl';
  variations: Variations;
  status: Status;
  contrast?: Contrast;
  label?: string;
  icon?: React.ReactNode;
  className?: string;
  isRounded?: boolean;
  leftX?: boolean;
  rightX?: boolean;
  highlight?: boolean;
};

const statusTransformations: Record<Status, string> = {
  inProgress: 'In Progress',
  complete: 'Complete',
  success: 'Success',
  error: 'Error',
  warning: 'Warning',
  refunded: 'Refunded',
  info: 'Info',
  notMapped: 'Not Mapped',
};

const statusComponents: Record<Status, React.ElementType | null> = {
  info: S.SvgIconBadgeInfo,
  success: S.SvgIconBadgeSuccess,
  error: S.SvgIconBadgeError,
  warning: S.SvgIconBadgeWarning,
  refunded: S.SvgIconBadgeRefunded,
  complete: null,
  inProgress: null,
  notMapped: S.SvgIconHelper,
};

const getStatusText = (status: Status): string => {
  return statusTransformations[status];
};

const Badge = ({
  contrast = 'high',
  label = '',
  size,
  icon,
  leftX = false,
  rightX = false,
  isRounded = false,
  status = 'notMapped',
  variations,
  highlight,
  className,
}: BadgeProps) => {
  const transformedStatus = label || getStatusText(status);
  const isIconBadge = variations === 'iconBadge';
  const isNotification = variations === 'notification';
  const isBadge = variations === 'badge';
  const isNewBadge = variations === 'newBadge';

  const containerClassName = clsx(
    `size-${size}`,
    `contrast-${contrast}`,
    `status-${status}`,
    `variations-${variations}`
  );

  const StatusComponent = statusComponents[status];

  return (
    <S.Container
      className={clsx(containerClassName, className)}
      $rounded={isRounded}>
      {isBadge && (
        <>
          <S.SvgCircle className={containerClassName} />
          <S.Text className={containerClassName}>{transformedStatus}</S.Text>
        </>
      )}

      {isIconBadge && StatusComponent && (
        <StatusComponent className={containerClassName} />
      )}

      {isNotification && size !== 'sm' && (
        <S.Text className={containerClassName}>{label}</S.Text>
      )}
      {isNewBadge && (
        <>
          {leftX && <S.SvgIconClose className={containerClassName} />}
          {highlight && (
            <S.SvgIconBadgeHighlight className={containerClassName} />
          )}
          {icon && (
            <S.ChangeIconContainter className={containerClassName}>
              {icon}
            </S.ChangeIconContainter>
          )}
          <S.Text className={containerClassName}>{label}</S.Text>
          {rightX && <S.SvgIconClose className={containerClassName} />}
        </>
      )}
    </S.Container>
  );
};

export { Badge };
